.helpSection {
    width: 100vw;
    background-color: var(--background-color-light);
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 4rem;
  }
  
  .icon {
    width: 30px;
    height: 30px;
    margin-left: 1rem;
  }

  .helpSection h2 {
    font-size: 16px;
    margin-right: 10rem;
    margin-top: 1.5rem;
  }
  
  .helpList {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .helpList a:hover {
    border-radius: 8px;
    background-color: #e0e0e0;
    overflow: hidden;
  }
  
  .helpItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    text-decoration: none;
    color: inherit;
  }
  
  .helpItemContent {
    display: flex;
    flex-direction: column;
    min-width: 20vw;
    margin-left: 1rem;
  }

  .helpTitle {
    font-size: 16px;
    font-weight: 500;
  }
  
  .helpDescription {
    font-size: 12px;
    color: #888;
  }

  /* Mobile */
@media (max-width: 700px) {
    .helpSection {
      flex-direction: column;
      padding: 2rem;
    }

    .helpSection h2 {
      margin-right: 0;
      margin-top: 0;
    }
    
    .helpList {
      gap: 10px;
    }
    
    .helpItem {
      padding: 10px;
    }
    
    .helpItemContent {
      width: 70vw;
    }
}