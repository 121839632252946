.topBar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    background-color: var(--background-color);
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .icons {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .iconButton {
    background: none;
    border: none;
    margin-left: 15px;
    font-size: 20px;
    cursor: pointer;
    outline: none;
    color: var(--icon-color);
  }
  
  .iconButton:hover {
    color: var(--primary-color);
  }
  
  .dropdownMenu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--background-color);
    /* border: 1px solid var(--primary-color); */
    border-radius: 5px;
    box-shadow: 0 8px 16px var(--shadow-color);
    z-index: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .dropdownItem {
    padding: 10px 20px;
    text-decoration: none;
    color: var(--primary-color);
    cursor: pointer;
  }
  
  .dropdownItem:hover {
    background-color: var(--primary-color);
    color: var(--background-color);
  }
  