.appItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color-light);
  border-radius: 34px;
  padding: 1.2rem 1rem;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 70vw;
}

.appItem:hover {
  background-color: #e0e0e0;
}

.appIcon {
  min-width: 30px;
  width: 30px;
  height: 30px;
  margin-left: 1rem;
  border-radius: 8px;
  overflow: hidden;
}

.appDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-left: 1rem;
}

.appName {
  margin: 0;
  font-size: 18px;
}

.appDescription {
  margin: 0;
  text-align: start;
  color: var(--primary-color-light);
  font-size: 14px;
}

.appCategory {
  margin-left: 15px;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  margin-right: 1rem;
  text-align: start;
  min-width: 200px;
}

.appCategoryCircle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  margin-bottom: 1px;
  background-color: #000;
}

.appActions {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.appArrow {
  margin-left: 10px;
  font-size: 16px;
}

/* Mobile */
@media (max-width: 700px) {
  .appItem {
    width: 90vw;
    border-radius: 8px;
    padding: 1rem 0.5rem;
  }

  .appIcon {
    margin-left: 0;
  }

  .appDetails {
    margin-left: 0.5rem;
    width: 120px;
  }

  .appCategory {
    min-width: 120px;
  }
}
