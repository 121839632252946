@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,500,1,200&display=swap");
@import url(./variables.css);

*, *::before, *::after { box-sizing: border-box; }

* { margin: 0; }

img, picture, video, canvas, svg { display: block; max-width: 100%; }

input, button, textarea, select { font: inherit; }

p, h1, h2, h3, h4, h5, h6 { overflow-wrap: break-word; }

.xxBody {
  height: 100%;
  font-family: "Almarai", "Times new Roman", "Helvetica neue", "Arial", sans-serif;
  font-size: var(--base-fontsize);
  color: var(--color-body);
  line-height: 1.5; 
  -webkit-font-smoothing: antialiased;
  background-color: var(--background-color);
}

.xxEnvironmentTraining, .xxEnvironmentPreproduction, .xxEnvironmentStaging, .xxEnvironmentTest { --color-environment-secondary-text: var(--color-body); }

.xxEnvironmentTraining { --color-environment-primary-text: var(--color-body); }

.xxEnvironmentDemo { --text-environment-bar: 'Demo omgeving - Te gebruiken voor het uitproberen van nieuwe functionaliteit. Gegevens worden NIET permanent opgeslagen.'; --surface-environment: var(--color-grey-500); --color-environment-bar-text: var(--color-body); }

.xxEnvironmentDevelopment { --text-environment-bar: 'Development'; --surface-environment: var(--color-grey-900); }

.xxEnvironmentPreproduction { --text-environment-bar: 'Pre-productie omgeving - Niet gebruiken voor dagelijkse werkzaamheden. De inhoud wordt regelmatig verwijderd.'; --color-environment-primary: var(--color-green-500); --color-environment-secondary: var(--color-green-200); --surface-environment: var(--color-environment-primary); }

.xxEnvironmentStaging { --text-environment-bar: 'Staging omgeving - Niet gebruiken voor dagelijkse werkzaamheden. De inhoud wordt regelmatig verwijderd.'; --color-environment-primary: var(--color-blue-500); --color-environment-secondary: var(--color-blue-200); --surface-environment: var(--color-environment-primary); }

.xxEnvironmentTest { --text-environment-bar: 'Test omgeving - de inhoud van deze omgeving wordt regelmatig verwijderd'; --color-environment-primary: var(--color-semantic-red-500); --color-environment-secondary: var(--color-semantic-red-200); --surface-environment: var(--color-environment-primary); }

.xxEnvironmentTraining { --text-environment-bar: 'Training omgeving - Niet gebruiken voor dagelijkse werkzaamheden. De inhoud wordt regelmatig verwijderd.'; --color-environment-primary: var(--color-yellow-500); --color-environment-secondary: var(--color-yellow-200); --surface-environment: var(--color-environment-primary); --color-environment-bar-text: var(--color-body); }

.xxAppLogoBlue .xxAppLogoVignet { --surface-vignet: var(--color-blue-500); }

.xxAppLogoGreen .xxAppLogoVignet { --surface-vignet: var(--color-green-500); }

.xxAppLogoOrange .xxAppLogoVignet { --surface-vignet: var(--color-orange-500); --text-vignet: var(--color-body); }

.xxAppLogoPurple .xxAppLogoVignet { --surface-vignet: var(--color-purple-500); }

.xxAppLogoRed .xxAppLogoVignet { --surface-vignet: var(--color-red-500); --text-vignet: var(--color-body); }

.xxAppLogoYellow .xxAppLogoVignet { --surface-vignet: var(--color-yellow-500); --text-vignet: var(--color-body); }

html, body { height: 100%; font-family: "Almarai", "Times new Roman", "Helvetica neue", "Arial", sans-serif; font-size: var(--base-fontsize); color: var(--color-body); }

.xxContainerLayout { height: 100vh; display: grid; grid-template-columns: 4fr 6fr; gap: 0px 0px; grid-template-areas: "client photo" "header photo" "main photo" "footer photo" "support_mobile photo"; grid-template-rows: 0fr auto 1fr; }

.xxAppHeader { padding: calc(var(--spacing-x-large) * 2) var(--spacing-xx-large) var(--spacing-x-large) var(--spacing-xx-large); display: flex; justify-content: center; align-items: center; grid-area: header; }

.xxAppHeaderContainer { max-width: var(--content-max-width); flex: 1; }

.xxLoginContent {
  padding: 0 var(--spacing-xx-large);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: main;
}

.xxLoginSection { flex: 1; max-width: var(--content-max-width); margin-bottom: calc(var(--spacing-x-large) * 2.5); }

.xxLoginFooter { padding: var(--spacing-large) var(--spacing-x-large); font-size: var(--fontsize-small); display: flex; gap: var(--spacing-large); flex-wrap: wrap; align-items: baseline; }

.xxHeaderPage { font-size: var(--fontsize-x-large); font-weight: 100; }

.xxAppLogo { display: flex; align-items: center; gap: var(--logo-spacing); font-size: var(--fontsize-large); font-weight: 600; }

.xxAppLogoVignet { padding-top: 2px; background: var(--surface-vignet); color: var(--text-vignet); display: inline-flex; justify-content: center; align-items: center; height: var(--logo-vignet-medium); width: var(--logo-vignet-medium); border-radius: var(--logo-vignet-radii); }

.xxLoginHeader { display: flex; justify-content: space-between; align-items: baseline; margin-bottom: var(--spacing-small); }

.xxLoginForm { display: flex; flex-direction: column; gap: var(--spacing-small); }

.xxLoginForm > .xxBtn { margin-top: var(--spacing-large); }

.xxLoginForm > .xxBtn ~ .xxBtn { margin-top: unset; }

.xxLoginInput { padding-inline: var(--spacing-medium); height: var(--height-large); border: solid 1px var(--color-grey-500); border-radius: var(--radius-small); }

.xxLoginInput::placeholder { opacity: 1; color: var(--color-grey-500); }

.xxLoginInput:hover { border-color: var(--color-grey-700); }

.xxLoginInput:focus, .xxLoginInput:focus-visible { outline-offset: unset; outline: 1px solid var(--color-grey-700); }

.xxLoginImage { object-fit: cover; width: 100%; height: 100%; }

.xxBtn { border: 0; border-radius: var(--radius-round); padding: var(--spacing-small); }

.xxBtn:hover { cursor: pointer; }

.xxBtnPrimary { background-color: var(--color-environment-primary); color: var(--color-environment-primary-text); }

.xxBtnPrimary:hover, .xxBtnPrimary:focus-visible { background: var(--color-grey-990); color: var(--color-grey-200); }

.xxBtnSecondary { background-color: var(--color-environment-secondary); color: var(--color-environment-secondary-text); }

.xxBtnSecondary:hover, .xxBtnSecondary:focus-visible { background: var(--color-grey-990); color: var(--color-grey-200); }

.xxLink { color: var(--color-body); }

.xxLink:hover { text-decoration: underline; text-underline-offset: calc(var(--spacing-x-small) / 2); }

.xxLinkIncognito { text-decoration: none; }

.xxLinkSmall { font-size: var(--fontsize-small); }

.xxPasswordContainer { position: relative; }

.xxPasswordContainer .xxLoginInput { width: 100%; padding-right: var(--spacing-xx-large); }

.xxPasswordToggle { position: absolute; right: 0; line-height: var(--height-medium); padding-inline: var(--spacing-medium); color: var(--color-body); cursor: pointer; }

body:not(.xxEnvironment) .xxEnvironmentName { display: none; }

.xxSideColumn { position: relative; grid-area: photo; }

.xxClientSpecificsContainer { background-color: var(--color-grey-200); border-radius: var(--radius-medium); position: absolute; right: var(--spacing-xx-large); top: var(--spacing-xx-large); padding: var(--spacing-large) var(--spacing-x-large); }

.xxClientSpecificsHeader { font-size: var(--fontsize-large); }

.xxClientSpecificsSupport { display: flex; gap: var(--spacing-small); justify-content: flex-end; }

.xxClientSpecificsSupportMobile { display: none; }

.xxLoginFooter { grid-area: footer; }

.xxLoginFooterAppName { font-size: var(--fontsize-medium); }

.xxLoginFooterLogo { height: 25px; width: 100px; }

@media (max-width: 1080px) {
  .xxLoginFooter { gap: var(--spacing-x-small); padding: var(--spacing-x-large); }
}

@media (max-width: 768px) {
  .xxContainerLayout { grid-template-columns: 1fr; grid-template-rows: 0fr 0fr auto 0fr 0fr; padding-bottom: var(--spacing-x-large); }
  .xxSideColumn { grid-area: client; display: flex; background: var(--color-grey-300); padding-inline: var(--spacing-xx-large); justify-content: center; }
  .xxAppHeader { padding: var(--spacing-xx-large) var(--spacing-xx-large) var(--spacing-large); }
  .xxClientSpecificsContainer { position: relative; top: unset; right: unset; padding: unset; background: unset; flex: 1; max-width: var(--content-max-width); padding-block: var(--spacing-x-large); }
  .xxClientSpecificsHeader { font-size: var(--fontsize-small); font-weight: normal; }
  .xxLoginImage { display: none; }
  .xxLoginFooter { flex-direction: column; align-items: center; padding-bottom: 0; }
}