:root {
    --base-fontsize: 16px;

    --content-max-width: 24rem;

    /* PRIMITIVES
    // primitives / spacing */
    --spacing-x-small: .214rem;
    --spacing-small: .5rem;
    --spacing-medium: 1rem;
    --spacing-large: 1.3rem;
    --spacing-x-large: 2rem;
    --spacing-xx-large: 2.8rem;
    /* primitives / fontsizes */
    --fontsize-small: .75rem;
    --fontsize-medium: 1rem;
    --fontsize-large: 1.3rem;
    --fontsize-x-large: 1.7rem;
    --fontsize-xx-large: 2.3rem;
    /* primitives / sizes */
    --height-x-small: 2rem;
    --height-small: 2.5rem;
    --height-medium: 3rem;
    --height-large: 3.5rem;
    --height-x-large: 5rem;
    /* primitives / radii */
    --radius-none: 0;
    --radius-small: .25rem;
    --radius-medium: .75rem;
    --radius-round: 10rem;

    /* PRIMITIVES / COLOURS
    // primitives / colours neutral */
    --color-grey-000: #FFFFFF;
    --color-grey-200: #F9F9F9;
    --color-grey-300: #F2F2F2;
    --color-grey-400: #DDDDE0;
    --color-grey-500: #B3B3B8;
    --color-grey-700: #8B8B90;
    --color-grey-800: #747476;
    --color-grey-900: #28282C;
    --color-grey-950: #232327;
    --color-grey-990: #0D0D0E;
    /* primitives / colours captainamerica */
    --color-blue-200: #D5E0EB;
    --color-blue-500: #31679B;
    --color-blue-700: #2C4A69;
    /* primitives / colours golfslag */
    --color-green-200: #CCE2DE;
    --color-green-500: #006E5B;
    --color-green-700: #124E46;
    /* primitives / colours halloween */
    --color-red-200: #FAE2DB;
    --color-red-500: #E4704A;
    --color-red-700: #8F4F3C;
    /* primitives / colours donatello */
    --color-purple-200: #D7CEDF;
    --color-purple-500: #74528E;
    --color-purple-700: #59436A;
    /* primitives / colours schoolbus */
    --color-orange-200: #FAEBD3;
    --color-orange-500: #E59925;
    --color-orange-700: #906628;
    /* primitives / colours zonnigezon */
    --color-yellow-200: #FCF4DC;
    --color-yellow-500: #F0C952;
    --color-yellow-700: #806D37;
    /* primitives / colours semantic */
    --color-semantic-blue-200: #D8E6F3;
    --color-semantic-blue-500: #31679B;
    --color-semantic-blue-700: #28537D;
    --color-semantic-green-200: #D1F0D6;
    --color-semantic-green-500: #006E5B;
    --color-semantic-green-700: #00473B;
    --color-semantic-orange-200: #FAEBD4;
    --color-semantic-orange-500: #E59925;
    --color-semantic-orange-700: #CB8418;
    --color-semantic-red-200: #F8D3D1;
    --color-semantic-red-500: #DD3228;
    --color-semantic-red-700: #BF271E;

    /* COLOURS / APPLIED */
    --color-body: var(--color-grey-900);
    --color-body-diapositive: var(--color-grey-300);
    --color-body-bright: var(--color-grey-000);
     
    --color-environment-primary: var(--color-blue-500);
    --color-environment-secondary: var(--color-grey-800);
    --color-environment-primary-text: var(--color-body-diapositive);
    --color-environment-secondary-text: var(--color-body-diapositive);
    --color-environment-bar-text: var(--color-body-diapositive);
    --color-semantic-success-text: var(--color-semantic-green-700);
    --color-semantic-success-surface: var(--color-semantic-green-200);
    --color-semantic-notify-text: var(--color-semantic-blue-700);
    --color-semantic-notify-surface: var(--color-semantic-blue-200);
    --color-semantic-warn-text: var(--color-semantic-orange-700);
    --color-semantic-warn-surface: var(--color-semantic-orange-200);
    --color-semantic-error: var(--color-semantic-red-500);
    --color-semantic-error-text: var(--color-semantic-red-700);
    --color-semantic-error-surface: var(--color-semantic-red-200);

    --color-brand: var(--color-yellow-500);
    --surface-vignet: var(--color-yellow-500);
    --text-vignet: var(--color-body-diapositive);
    --font-family-icons:'Material Symbols Outlined';
    --font-family-content:"Almarai", "Helvetica neue", "Arial", sans-serif;

    --background-dark: var(--color-grey-900);
    --background-light: var(--color-grey-000);
     
    --shaded-neutral-xx-light: var(--color-grey-200);
    --shaded-neutral-x-light: var(--color-grey-300);
    --shaded-neutral-light: var(--color-grey-400);
    --shaded-neutral-medium: var(--color-grey-500);
    --shaded-neutral-x-dark: var(--color-grey-950);

    --border-button-subtle: var(--color-grey-500);
    --border-button-contrast: var(--color-grey-900);


    /* GENERIC */
    --dropshadow-elevation-5: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
    --dropshadow-elevation-10: 0 3px 6px 1px rgba(0, 0, 0, 0.35);

    --transition-hover:  background-color .25s ease-out;
    --transition-panel:  all .35s ease-out;


    /* SPECIFICS */
    /* App logo's */
    --logo-spacing: 1rem;
    --logo-vignet-small:  var(--height-x-small);
    --logo-vignet-medium: var(--height-small);
    --logo-vignet-large:  var(--height-medium);
    --logo-organisation-mobile: 2.6rem;
    --logo-vignet-radii: calc(var(--logo-vignet-medium) / 4) / calc(var(--logo-vignet-medium) / 3.5); /* TODO: make for all sizes */

    /* xxllnc Login */
    --text-environment-bar:'';
 
}

[data-theme="dark"] {
    --color-body: var(--color-body-diapositive);
  }