.footer {
    width: 100vw;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  
  .year {
    margin-left: auto;
    color: var(--primary-color);
    font-size: 10pt;
  }