.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--primary-color);
}

.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* min-height: 40vh; */
}

.welcome {
  font-size: 2rem;
  margin-bottom: 5rem;
  font-weight: 500;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 2rem;
}

/* Mobile */
@media (max-width: 700px) {
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
}
